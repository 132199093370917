import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../../src/images/logo.svg"
import { Link } from "gatsby"

const Success = () => (
  <Layout>
    <SEO title="Danke!" />
    <div className="success">
      <img src={logo} alt="Blessing" className="success__logo" />
      <h2>Danke für deine Nachricht!</h2>
      <p>Ich werde mich in kürze bei dir melden.</p>
      <Link className="success__link" to="/">
        Zur Startseite
      </Link>
    </div>
  </Layout>
)

export default Success
